*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Avenir-Roman, sans-serif;

}

body, html{
    padding: 0
}

